import { request } from "@/api/_base";

export const addSpiderKeyword = async (keyword) => {
  return await request({
    method: "POST",
    url: `/api/v1/spider/add`,
    data: { keyword },
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const deleteSpiderKeyword = async (id) => {
  return await request({
    method: "POST",
    url: `/api/v1/spider/delete`,
    data: { id },
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const querySpiderKeyword = async () => {
  return await request({
    url: `/api/v1/spider/query`,
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};
