<template>
  <div class="spider-page" v-if="userinfo">
    <div class="intro-header">
      <div class="left">
        <h1>
          个人爬虫
          <el-tag type="warning" v-if="userinfo.expired">未开通服务</el-tag>
          <el-tag type="success" v-if="!userinfo.expired"
            >{{ userinfo.expiredate | formatDate }}服务到期</el-tag
          >
        </h1>
        <p v-if="userinfo.expired">
          *
          同学，你是否要租用线路进行网络爬虫，开通百度网盘、阿里云盘、夸克网盘的相关公开分享链接的网络爬虫服务。<br />*
          爬虫服务需要开通会员才能使用哦！
        </p>
        <p v-else>
          *
          同学，你是否要租用线路进行网络爬虫，开通百度网盘、阿里云盘、夸克网盘的相关公开分享链接的网络爬虫服务。<br />
          *
          是否明白爬取的资源可能存在版权问题，并清楚只能作为版权监测服务和个人学习用途，如再次分享，将追究你个人的法律责任。<br />
          *
          本站仅为你提供爬虫服务，并概不确定能否爬取到资源，也不负责爬取任何资源的版权问题。<br />
          *
          是否清楚爬虫具备时效性，取决于原分享者设定的分享有效期及网盘的运营及产品政策，本站仅为你提供爬虫服务，并概不负责资源的有效性问题。<br />
        </p>
      </div>
      <div class="right">
        <el-button
          type="primary"
          icon="el-icon-circle-check"
          @click="showPayDialog"
          >服务{{ userinfo.expiredate ? "续订" : "开通" }}</el-button
        >
        <el-button type="primary" icon="el-icon-plus" @click="handleAddKeyword"
          >添加爬虫服务</el-button
        >
      </div>
    </div>
    <Pay v-if="userinfo.expired" />
    <template v-else>
      <div class="result-wrap" v-loading="loading">
        <div class="no-result" v-if="!results.length && !loading">
          <img src="@/assets/images/cover.png" class="none-image" />
          <p class="none-tip">暂无服务列表，快来添加服务吧~</p>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="handleAddKeyword"
            >添加爬虫服务</el-button
          >
        </div>
        <div class="result-panel" v-if="results.length > 0">
          <h3>爬虫服务列表</h3>
          <el-table :data="results">
            <el-table-column prop="keyword" label="爬虫名称">
              <template slot-scope="scope">
                <p class="keyword">
                  <img src="../assets/images/folder.png" />
                  {{ scope.row.keyword }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="createtime" label="添加时间" width="200">
              <template slot-scope="scope">
                <p>
                  {{ scope.row.createtime | formatTime }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-tag type="success" size="small">爬取中</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="110">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="margin-right: 12px"
                  @click="handlePreview(scope.row.keyword)"
                  >查看</el-button
                >
                <el-popover
                  placement="top"
                  width="160"
                  v-model="scope.row.visible"
                >
                  <p>确认删除？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="scope.row.visible = false"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="handleDeleteKeyword(scope.row)"
                      >确定</el-button
                    >
                  </div>
                  <el-button type="text" class="danger" slot="reference"
                    >删除</el-button
                  >
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  addSpiderKeyword,
  deleteSpiderKeyword,
  querySpiderKeyword,
} from "../api/spider";
import Pay from "../components/Pay.vue";
export default {
  components: {
    Pay,
  },
  data() {
    return {
      loading: false,
      results: [],
    };
  },
  mounted() {
    this.refreshKeywords();
  },
  methods: {
    handlePreview(keyword) {
      this.openWindow(
        `https://www.enfi.vip/#/main/search?keyword=${encodeURIComponent(
          keyword
        )}`,
        true
      );
    },
    refreshKeywords() {
      if (this.loading) return;
      this.loading = true;
      querySpiderKeyword()
        .then((res) => {
          if (res.code === 0) {
            this.results = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDeleteKeyword(item) {
      item.visible = false;
      deleteSpiderKeyword(item.id).then((res) => {
        if (res.code === 0) this.refreshKeywords();
      });
    },
    handleAddKeyword() {
      this.$messagebox
        .prompt("请输入爬虫关键词", "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(({ value }) => {
          if (!value) return this.$message.error("关键词不能为空");
          if (value.length > 60)
            return this.$message.error("关键词不能超过60个字");
          addSpiderKeyword(value).then((res) => {
            if (res.code === 0) this.refreshKeywords();
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.spider-page {
  padding: 30px 40px;
  max-width: 100%;
  .intro-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      flex: auto;
      margin-right: 20px;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .el-button {
        margin-bottom: 15px;
        &:last-child {
          margin-left: 0;
        }
      }
    }
    h1 {
      display: flex;
      align-items: center;
      .el-tag {
        margin-left: 10px;
      }
    }
    p {
      color: #514f6e;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .result-wrap {
    margin-top: 20px;
    padding: 24px 32px;
    border-radius: 10px;
    background-color: #fff;
    min-height: 160px;
    max-width: 100%;

    .keyword {
      display: flex;
      align-items: center;
      img {
        height: 20px;
        margin-right: 8px;
      }
    }
    .no-result {
      text-align: center;
      padding: 60px 0;
      .none-image {
        width: 200px;
      }
      .none-tip {
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 700px) {
  .spider-page {
    padding: 15px;
    .intro-header {
      flex-direction: column;
      .left {
        margin-right: 0;
      }
      .right {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        align-items: space-around;
        margin-bottom: 0;
      }
    }
    .result-wrap {
      margin-top: 10px;
      padding: 10px;

      .no-result {
        text-align: center;
        padding: 20px 0;
        .none-image {
          width: 100px;
        }
        .none-tip {
          margin-bottom: 10px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
